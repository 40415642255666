import React from "react"
import { Router, useLocation } from "@reach/router"
import { usePreview } from "../../hooks/usePreview"

import ClassPageTemplate from "../../templates/class"

const PreviewPage: React.FC = () => {
  const location = useLocation()
  const [previewData] = usePreview(location.href)
  // console.log('refresh; previewData', previewData);

  if (previewData === null) {
    return <div>Loading...</div>
  }

  return (
    <Router basepath="/preview">
      <ClassPageTemplate path="/classes/:slug" data={previewData} />
    </Router>
  )
}

export default PreviewPage

import axios from "axios"

export const getDataFromPreviewUrl = async (previewUrl: string): Promise<any> => {
  switch(previewUrl.split("/")[4]) {
    case "classes": {
      const response = await axios.post("/.netlify/functions/getDraftDocument", {
        _type: "class",
        slug: previewUrl.split("/")[5],
        queryFields: `"image": image.asset->url,`,
      });
      const draftDocument = {
        class: {
          ...response.data.document,
          _rawFlavorText: response.data.document.flavorText
        }
      };
      return draftDocument;
    }
  }  
}

import { useState, useEffect } from 'react'
import { getDataFromPreviewUrl } from "../utils/getDataFromPreviewUrl"

export const usePreview = (previewUrl: string) => {
  const [data, setData] = useState<any|null>(null);

   useEffect(() => {
    const fetchData = async () => {
      try {
        const document = await getDataFromPreviewUrl(previewUrl);
        return setData(document);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return [data]
}

import React from 'react'
import { graphql } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import BlockContent from "@sanity/block-content-to-react"

import Image from "../../components/image"
import Layout from "../../components/layout"

interface DataProps {
  data: {
    class: {
      id: string,
      name: string,
      image: any,
      _rawFlavorText: string,
    }
  }
}

const ClassPageTemplate: React.FC<DataProps & RouteComponentProps> = ({ data }) => {  
  return (
    <Layout>
      <div className="block">
        <div className="max-w-4xl mx-auto px-6 sm:px-0">
          <div className="mt-12">
            <h1 className="text-mocha text-5xl font-display font-bold">
              The { data.class.name }
            </h1>
          </div>
          <div className="w-80 sm:float-right">
            <Image className="h-full w-full object-cover" image={data.class.image} alt={`${data.class.name} Avatar`} />
          </div>

          <div className="max-w-none mx-auto prose lg:prose-lg">
            <BlockContent blocks={data.class._rawFlavorText} />
          </div>
        </div>      
      </div>
    </Layout>    
  )
}

export default ClassPageTemplate

export const query = graphql`
  query ClassQuery($slug: String!) {
    class: sanityClass(slug: { current: {eq: $slug }}) {
      id
      name
      _rawFlavorText
      image {
        asset {
          fluid(maxWidth: 500) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
import React from "react"
import Img from "gatsby-image"

interface Props {
  image: any,
  alt?: string,
  className?: string,
}

const Image: React.FC<Props> = ({ image, alt = "", className = "" }) => {    
  if (image?.asset?.fluid) {
    return <Img className={className} fluid={image.asset.fluid} alt={alt} />        
  }

  if ((/https?\:\/\//).test(image)) {
    return <img className={className} src={image} alt={alt} />
  }

  return null;
}

export default Image
